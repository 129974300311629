import "reflect-metadata";
import { Type } from "class-transformer";
import { Tag } from "./tag";
import { marked } from "marked";

export enum PlaceType {
  DOG_RUN = "dog-run",
  DOG_CAFE = "dog-cafe",
  DOG_HOTEL = "dog-hotel",
  PET_SALON = "pet-salon",
  CAMPSITE = "campsite",
}

export class Place {
  id: number;
  name: string;
  address1: string;
  address2: string;

  lonlat: object;
  tel: string;
  url: string;
  aff_url: string;
  catch: string;
  content: string;
  open_hour_free_text: string;

  image1: object;
  image2: object;
  image3: object;

  @Type(() => Tag)
  place_type_tags: Array<Tag>;
  dog_run_feature_tags: Array<Tag>;
  dog_cafe_feature_tags: Array<Tag>;
  dog_hotel_feature_tags: Array<Tag>;
  pet_salon_feature_tags: Array<Tag>;
  campsite_feature_tags: Array<Tag>;

  created_at: string;
  updated_at: string;

  get lng() {
    return this.lonlat.x;
  }

  get lat() {
    return this.lonlat.y;
  }

  get detailPath() {
    return `/places/${this.id}`;
  }

  get fullAddress() {
    return `${this.address1}${this.address2}`;
  }

  get itemType() {
    return this.place_type_tags[0].slug;
  }

  get placeType() {
    switch (this.itemType) {
      case "dog-run":
        return PlaceType.DOG_RUN;
        break;
      case "dog-cafe":
        return PlaceType.DOG_CAFE;
        break;
      case "dog-hotel":
        return PlaceType.DOG_HOTEL;
        break;
      case "pet-salon":
        return PlaceType.PET_SALON;
        break;
      case "campsite":
        return PlaceType.CAMPSITE;
        break;
    }
  }

  get features() {
    switch (this.itemType) {
      case "dog-run":
        return this.dog_run_feature_tags;
        break;
      case "dog-cafe":
        return this.dog_cafe_feature_tags;
        break;
      case "dog-hotel":
        return this.dog_hotel_feature_tags;
        break;
      case "pet-salon":
        return this.pet_salon_feature_tags;
        break;
      case "campsite":
        return this.campsite_feature_tags;
        break;
    }
  }

  get parsed_content() {
    return marked(this.content);
  }
  toJSON() {
    return { ...this };
  }
}
