import { defineStore } from "pinia";
import { plainToClass } from "class-transformer";
import { Tag } from "@/models/tag";
import tags from "~/assets/data/tags.json";

export const useMasterDataStore = defineStore("masterData", () => {
  const dogRunTags = ref([]);
  const dogCafeTags = ref([]);
  const dogHotelTags = ref([]);
  const petSalonTags = ref([]);
  const campsiteTags = ref([]);

  function fetchAll() {
    dogRunTags.value = plainToClass(Tag, tags["dog_run"]);
    dogCafeTags.value = plainToClass(Tag, tags["dog_cafe"]);
    dogHotelTags.value = plainToClass(Tag, tags["dog_hotel"]);
    petSalonTags.value = plainToClass(Tag, tags["pet_salon"]);
    campsiteTags.value = plainToClass(Tag, tags["campsite"]);
    return tags;
  }

  return {
    dogRunTags,
    dogCafeTags,
    dogHotelTags,
    petSalonTags,
    campsiteTags,
    fetchAll,
  };
});
