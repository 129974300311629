<script lang="ts" setup>
import { plainToClass } from "class-transformer";
import { Place, PlaceType } from "@/models/place";
import { usePlaceStore } from "@/stores/place";
import { useNewsStore } from "@/stores/news";

definePageMeta({
  layout: "default",
});

const placeStore = usePlaceStore();
await useAsyncData("latest-places-all", () => placeStore.fetchLatestAll());

const newsStore = useNewsStore();
await useAsyncData("news", () => newsStore.fetchAll());
</script>

<template lang="pug">

TopSearchForm
v-container(fluid mb-sm-12)
  v-row(align="center" align-content="center" justify="center")
    v-col(cols="12")
      h2 DOG RUN
      h3 新着のドッグラン

PlaceItems(:items="placeStore?.dogRunList" v-if="placeStore?.dogRunList?.length > 0")

v-container(fluid mb-sm-12)
  v-row(align="center" align-content="center" justify="center")
    v-col(cols="12")
      h2 DOG CAFE
      h3 新着の愛犬と入れるカフェ

PlaceItems(:items="placeStore?.dogCafeList" v-if="placeStore?.dogCafeList?.length > 0")

v-container(fluid mb-sm-12)
  v-row(align="center" align-content="center" justify="center")
    v-col(cols="12")
      h2 DOG HOTEL
      h3 新着の愛犬と泊まれるホテル

PlaceItems(:items="placeStore?.dogHotelList" v-if="placeStore?.dogHotelList?.length > 0")

v-container(fluid mb-sm-12)
  v-row(align="center" align-content="center" justify="center")
    v-col(cols="12")
      h2 CAMPSITE
      h3 新着の愛犬とのキャンプ

PlaceItems(:items="placeStore?.campsiteList" v-if="placeStore?.campsiteList?.length > 0")

v-container(fluid mb-sm-12)
  v-row(align="center" align-content="center" justify="center")
    v-col(cols="12")
      h2 DOG SALON
      h3 新着の愛犬のためのサロン

PlaceItems(:items="placeStore?.petSalonList" v-if="placeStore?.petSalonList?.length > 0")

v-container(fluid mb-sm-12)
  v-row(align="center" align-content="center" justify="center")
    v-col(cols="12")
      v-row: h2 MAGAZINE
      v-row: h3 マガジン

TopArticleItems

v-container(class="news" fluid)
  v-row(id="news" align="center" align-content="center" justify="center")
    v-col(cols="12")
      h2 NEWS
      h3 お知らせ
  v-row(
    align="center"
    align-content="center"
    justify="center"
    class="mb-5"
  )
    v-sheet(
      border
      rounded
      max-width="800"
      width="100%"
      height="400"
      class="rounded-lg"
    )
      v-row(
        v-for="news in newsStore?.newsList"
        :key="news.id"
      )
        v-col(
          cols="12"
          md="3"
          class="date text-left text-md-center pt-0 pb-0 pt-md-4 pd-md-4"
        ) {{news.disp_published_at}}
        v-col(
          cols="12"
          md="9"
          class="text-left title font-weight-medium"
        )
          NuxtLink(:to="news.detail_path") {{news.title}}

CategoryList

</template>

<style lang="scss" scoped>
.v-container {
  text-align: center;
  color: #737373;

  h2 {
    width: 280px;
    height: 52px;
    margin: 0 auto;
    font-family: brandon-grotesque, sans-serif;
    font-weight: 600;
    font-size: 36px;
    letter-spacing: 2.16px;
    background-color: #fff;
  }

  h3 {
    margin: 0 auto;
    font-family: a-otf-gothic-bbb-pr6n, sans-serif;
    font-size: 16px;
    letter-spacing: -0.56px;
  }
}

.news {
  background-color: #edf3f8;
  h2 {
    width: 100%;
    font-size: 26px;
    background-color: #edf3f8;
  }

  .date {
    margin-top: 5px;
    color: #737373;
  }

  .title {
    margin-top: 5px;

    a,
    a:visited {
      text-decoration: none;
      cursor: pointer;
      color: black;
    }

    a:hover {
      text-decoration: none;
      opacity: 0.7;
    }
  }
  .v-sheet {
    padding: 20px 10px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

@media (max-width: 576px) {
  .v-container {
    img {
      width: 100%;
      height: auto;
      padding: 0;
    }

    h2 {
      width: 210px;
      height: 55px;
      bottom: 0px;
      padding-top: 20px;
      font-size: 22px;
      letter-spacing: 1.44px;
    }

    h3 {
      font-size: 14px;
      letter-spacing: -0.56px;
      bottom: -22px;
    }
  }

  .cate-list .v-img {
    max-width: none;
  }
}
</style>
