import { defineStore } from "pinia";
import { plainToClass } from "class-transformer";
import { News } from "@/models/news";

export const useNewsStore = defineStore("news", () => {
  const newsList = ref([]);

  async function fetchAll() {
    const params = {
      offset: 20,
      page: 1,
    };
    const { press_releases: json } = await apiFetch(`/press_releases`, {
      params,
    });
    newsList.value = plainToClass(News, json);
    return newsList;
  }

  return {
    newsList,
    fetchAll,
  };
});
