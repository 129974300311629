<script lang="ts" setup>
import areas from "~/assets/data/areas";
import { useMasterDataStore } from "@/stores/master_data";

onBeforeMount(() => Typekit.load({ async: true }));

const masterDataStore = useMasterDataStore();
masterDataStore.fetchAll();
</script>

<template lang="pug">
v-app
  v-layout    
    HeaderNavBar(h1-string="人気のドッグラン、愛犬と入れるカフェ、ホテル、サロンをさがそう")
    v-main      
      slot
      v-container.seo(fluid mb-sm-12)
        v-row
          v-col(cols="12" offset="1"): h2.text-left 都道府県から探す
        v-row.pb-4
          v-col(cols="12" offset="1"): h3.text-left
            v-icon(small) mdi-paw
            | ドッグラン
        FooterPrefPlaceLinks(
          v-for="area in areas"
          :area="area"
          :key="area.id"
          place-type="dog-run"
        )
        v-row.pb-4
          v-col(cols="12" offset="1"): h3.text-left
            v-icon(small) mdi-coffee
            |     愛犬と入れるカフェ
        FooterPrefPlaceLinks(
          v-for="area in areas"
          :area="area"
          :key="area.id"
          place-type="dog-cafe"
        )
        v-row.pb-4
          v-col(cols="12" offset="1"): h3.text-left
            v-icon(small) mdi-bed
            |     愛犬と泊まれるホテル
        FooterPrefPlaceLinks(
          v-for="area in areas"
          :area="area"
          :key="area.id"
          place-type="dog-hotel"
        )
        v-row.pb-4
          v-col(cols="12" offset="1"): h3.text-left
            v-icon(small) mdi-campfire
            |     愛犬とキャンプ
        FooterPrefPlaceLinks(
          v-for="area in areas"
          :area="area"
          :key="area.id"
          place-type="campsite"
        )
        v-row.pb-4
          v-col(cols="12" offset="1"): h3.text-left
            v-icon(small) mdi-shower
            |     愛犬のためのサロン
        FooterPrefPlaceLinks(
          v-for="area in areas"
          :area="area"
          :key="area.id"
          place-type="pet-salon"
        )

      v-container.seo(fluid mb-sm-12)
        v-row
          v-col(cols="12" offset="1"): h2.text-left 特徴から探す
        v-row.pb-4
          v-col(cols="12" offset="1"): h3.text-left
            v-icon(small) mdi-paw
            | ドッグラン
          FooterTagLinks(
            :tags="masterDataStore.dogRunTags"
            place-type="dog-run"
          )
        v-row.pb-4
          v-col(cols="12" offset="1"): h3.text-left
            v-icon(small) mdi-coffee
            |     愛犬と入れるカフェ
          FooterTagLinks(
            :tags="masterDataStore.dogCafeTags"
            place-type="dog-cafe"
          )
        v-row.pb-4
          v-col(cols="12" offset="1"): h3.text-left
            v-icon(small) mdi-bed
            |     愛犬と泊まれるホテル
          FooterTagLinks(
            :tags="masterDataStore.dogHotelTags"
            place-type="dog-hotel"
          )
        v-row.pb-4
          v-col(cols="12" offset="1"): h3.text-left
            v-icon(small) mdi-campfire
            |     愛犬とキャンプ
          FooterTagLinks(
            :tags="masterDataStore.campsiteTags"
            place-type="campsite"
          )
        v-row.pb-4
          v-col(cols="12" offset="1"): h3.text-left
            v-icon(small) mdi-shower
            |     愛犬のためのサロン
          FooterTagLinks(
            :tags="masterDataStore.petSalonTags"
            place-type="pet-salon"
          )
  Footer
</template>

<style lang="scss" scoped>
.v-layout {
}
.v-container {
  text-align: center;
  color: #737373;

  h2 {
    width: 280px;
    height: 52px;
    margin: 0 auto;
    font-family: brandon-grotesque, sans-serif;
    font-weight: 600;
    font-size: 36px;
    letter-spacing: 2.16px;
    background-color: #fff;
  }

  h3 {
    margin: 0 auto;
    font-family: a-otf-gothic-bbb-pr6n, sans-serif;
    font-size: 16px;
    letter-spacing: -0.56px;
  }
}

.seo {
  background-color: #edf3f8;
  h2 {
    width: 100%;
    font-size: 26px;
    background-color: #edf3f8;
  }

  h3 {
    font-size: 14px;
  }

  a {
    font-size: 10px;
  }

  .subtitle {
    margin-top: 40px;

    h4 {
      font-size: 13px;
    }
  }

  .table-label {
    text-align: center;
  }

  .table-body {
    margin-left: 5px;
  }
}
@media (max-width: 576px) {
  .v-container {
    img {
      width: 100%;
      height: auto;
      padding: 0;
    }

    h2 {
      width: 210px;
      height: 55px;
      bottom: 0px;
      padding-top: 20px;
      font-size: 22px;
      letter-spacing: 1.44px;
    }

    h3 {
      font-size: 14px;
      letter-spacing: -0.56px;
      bottom: -22px;
    }
  }
}
</style>
