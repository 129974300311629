import { defineStore } from "pinia";
import { plainToClass } from "class-transformer";
import { Place, PlaceType } from "@/models/place";

export const usePlaceStore = defineStore("place", {
  state: () => ({
    dogRunList: [],
    dogCafeList: [],
    dogHotelList: [],
    petSalonList: [],
    campsiteList: [],
    place: null,
  }),
  actions: {
    async fetchLatest(placeType: PlaceType) {
      const { $_ } = useNuxtApp();
      const { places: json } = await apiFetch(`/places/latest`, {
        query: { "place_type_tags[]": [placeType] },
      });
      const key = `${$_.camelCase(placeType)}List`;
      this[key] = plainToClass(Place, json);
    },
    async fetchLatestAll() {
      const { $_ } = useNuxtApp();
      const { places } = await apiFetch(`/places/latest_by_type`);
      this.dogRunList = plainToClass(Place, places["dog_run"]);
      this.dogCafeList = plainToClass(Place, places["dog_cafe"]);
      this.dogHotelList = plainToClass(Place, places["dog_hotel"]);
      this.petSalonList = plainToClass(Place, places["pet_salon"]);
      this.campsiteList = plainToClass(Place, places["campsite"]);
    },
    async fetch(id: number) {
      const { place: json } = await apiFetch(`/places/${id}`);

      this.place = plainToClass(Place, json);
    },
  },
});
